import React from "react";

import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import Page from "../../organisms/Page";
import Sidebar from "../../molecules/Sidebar";

import * as p from "../../organisms/Page/styles.module.scss";
import * as s from "./styles.module.scss";

const { baseUrl, imageCdnUrlOpt } = domains;

export default function About() {
    return (
        <Page
            metaTitle="About me"
            canonicalUrl={`${baseUrl}/about`}
            metaDescription="Learn more about Panagiotis Andreou and his astrophotography."
            heroImages={[
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_M_y52spo.jpg`, // 768 Mobile
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_M_y52spo.jpg`, // 992 Tablet
                `${imageCdnUrlOpt}/Milky_Way/Milky_Way_Iria_0818_L_knukad.jpg`, // 2880 Desktop
            ]}
            isFullBanner={false}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <Heading level={1} size="large" className={p.heading}>
                        About me
                    </Heading>

                    <p>
                        My name is Panagiotis Andreou (AKA Takis). I am a
                        software engineer by day, amateur astrophotographer and
                        astronomer by night.
                    </p>
                    <p>
                        All these photons from stars and distant deep sky
                        objects caught my interest when I was really young. I
                        was spending hours looking at them with the naked eye.
                        Two decades later, I got my first telescopes. I was
                        hooked. I was going deeper and deeper into the cosmos.
                    </p>
                    <p>
                        <img
                            src={`${imageCdnUrlOpt}/Equipment/me-and-fsq-85edp_tfewgr.jpg`}
                            title="Mmy trusty astrograph"
                            alt="My trusty astrograph"
                            className={s.image}
                            style={{ maxHeight: 680 }}
                        />
                        <br />
                        <i>My trusty astrograph</i>
                    </p>
                    <p>
                        Since summer 2017, I started taking my first
                        astrophotos. I began with constellations, our solar
                        system and our galaxy, the Milky Way. Then I moved on to
                        all kind of deep sky objects from galaxies and clusters
                        to nebulae and supernovae. From stock Nikon DSLRs, wide
                        lens and telephotos to Takahashi refractors, Nikon
                        astromodofied DSLRs, cooled monochrome astro-cameras and
                        Astrodon narrowband and LRGB filters.
                    </p>
                    <p>
                        <img
                            src={`${imageCdnUrlOpt}/Milky_Way/Milky_Way_Kefalonia_Summer_2017_S_rzw1bf.jpg`}
                            title="Milky Way Kefalonia, Summer 2017"
                            alt="Milky Way Kefalonia"
                            className={s.image}
                        />
                        <br />
                        <i>My very first milky way photograph</i>
                    </p>
                    <p>
                        I feel so lucky I can enjoy them in all their glory
                        through my photographs. This is what I want to share
                        with you. I hope you enjoy them as much as I do.
                    </p>
                </div>
                <Sidebar />
            </div>
        </Page>
    );
}
